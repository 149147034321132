import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGithub, faGitlab, faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';

export const Footer: React.FC = () => (
    <footer className="py-3 bg-blue-200 mt-auto">
        <div className="container">
            <div className="flex items-center">
                <div className="flex-grow ml-6">
                    <h3 className="mb-0">Lewis Tierney</h3>
                </div>
                <a href="https://www.linkedin.com/in/lewis-tierney-582a48143" className="mr-2"><FontAwesomeIcon icon={faLinkedin} /></a>
                <a href="https://github.com/yomanz" className="mr-2"><FontAwesomeIcon icon={faGithub} /></a>
                <a href="https://twitter.com/daave360" className="mr-2"><FontAwesomeIcon icon={faTwitter} /></a>
                <a href="https://gitlab.com/daave" className="mr-6"><FontAwesomeIcon icon={faGitlab} /></a>
            </div>
        </div>
    </footer>
)
import React from 'react';

export interface Work {
    name: string;
    description: string;
    tags: string[];
    image: string;
    link?: string;
}

export const WorkCard: React.FC<Work & {index: number}> = ({index, name, description, tags, image, link}) => {
    const isOdd = ((index % 2) === 1);

    // TODO: There is a bug where the picture overflows incorrectly.
    const picture = (
        <div className="lg:w-2/3 md:w-full px-0 mt-12 lg:my-12">
            <div
                className="relative flex flex-col min-w-0 rounded break-words bg-white h-full">
                <img src={image} className="max-w-full h-auto" alt="Work"/>
            </div>
        </div>
    );

    const content = (
        <div className="lg:w-1/3 md:w-full text-left px-0 mb-12 lg:my-12">
            <div
                className="relative flex flex-col min-w-0 rounded break-words bg-white p-12 h-full">
                <h3 className="text-3xl">{link ? <a className="hover:text-blue-400 hover:font-bold" href={link}>{name}</a> : name}</h3>
                <p className="text-gray-700">
                    {description}
                </p>
                <div className="flex flex-wrap tags">
                    {tags.map((t, i) => <span className="rounded-full lowercase font-bold bg-blue-200 pl-2 pr-2 p-0.5 m-0.5" key={i}>{t}</span>)}
                </div>
            </div>
        </div>
    );

    return isOdd ? <>{content}{picture}</> : <>{picture}{content}</>;
};
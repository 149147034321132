import React from 'react';
import {Work, WorkCard} from './components/WorkCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBacon,
    faCogs,
    faDatabase,
    faGamepad,
    faGolfBall, faSearch,
    faServer
} from '@fortawesome/free-solid-svg-icons';
import {Footer} from './components/Footer';
import {ScrollArrow} from './components/ScrollArrow';

const work: Work[] = [
    {
        name: 'WidgetBot',
        link: 'https://widgetbot.io/',
        description: 'Pixel perfect discord embed for your website.',
        tags: ['Kubernetes', 'PostgreSQL', 'Scala', 'TypeScript'],
        image: 'wb.png'
    },
    {
        name: 'Monolith Servers',
        link: 'https://monolithservers.com/',
        description: 'A custom multiplayer experience, built in Garry\'s Mod.',
        tags: ['lua', 'gmod', 'scala', 'typescript'],
        image: 'monolith.png'
    },
    {
        name: 'MonoSuite',
        description: 'A game agnostic SaaS solution for administration and monetization of game servers, coming soon!',
        tags: ['frontend', 'backend', 'scala', 'saas'],
        image: 'monosuite.png'
    }
];

const WorkSection: React.FC = () => (
    <section className="work text-center pt-0">
        <h2>work</h2>
        <div className="flex flex-wrap content">
            {work.map((w, i) =>
                <WorkCard key={i} index={i} name={w.name} link={w.link} description={w.description}
                          tags={w.tags} image={w.image}/>)
            }
        </div>
    </section>
)

const AboutSection: React.FC = () => (
    <section className="about text-center">
        <h2>about me</h2>
        <div className="md:flex items-center content">
            <div className="flex-shrink-0 md:pr-12 mb-12 md:mb-0">
                <img className="max-w-full h-auto"
                     src="me.jpg"
                     alt="avatar"/>
            </div>
            <div
                className="flex-grow text-left relative flex flex-col min-w-0 rounded-lg break-words md:border bg-white border-1 border-gray-300 p-12">
                <p>
                    Lewis Tierney, 20 years of age, currently studying Software Engineering in <a className="hover:text-blue-800 text-blue-600" href="https://www.aber.ac.uk/">Aberystwyth University</a>, founder of <a className="hover:text-blue-800 text-blue-600" href="https://widgetbot.io">WidgetBot</a>, currently working on SaaS administration for games over at <a className="hover:text-blue-800 text-blue-600" href="https://thrivingventures.com">Thriving Ventures</a>.
                </p>
            </div>
        </div>
    </section>
)

const SkillsSection: React.FC = () => (
    <section className="text-center pt-0 skills">
        <h2>skills</h2>
        <div className="md:flex items-center content">
            <div className="flex flex-col flex-grow relative rounded-lg break-words bg-white border border-1 border-gray-300">
                <div className="grid grid-cols-2 divide-x-2">
                    <div className="pt-1 pb-7 flex flex-col text-left">
                        <h2 className="p-5 text-3xl text-center">Languages</h2>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faDatabase} />
                            <span className="text-2xl"> Scala</span>
                        </div>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faBacon} />
                            <span className="text-2xl"> TypeScript</span>
                        </div>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faGamepad} />
                            <span className="text-2xl"> C#</span>
                        </div>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faGolfBall} />
                            <span className="text-2xl"> Lua</span>
                        </div>
                    </div>
                    <div>
                        <h2 className="p-5 text-3xl">Technologies/Skills</h2>
                        <div>
                            <div className="items-center text-center">
                                <FontAwesomeIcon icon={faServer} />
                                <span className="text-2xl"> Kubernetes</span>
                            </div>
                            <div className="items-center text-center">
                                <FontAwesomeIcon icon={faDatabase} />
                                <span className="text-2xl"> PostgreSQL</span>
                            </div>
                            <div className="items-center text-center">
                                <FontAwesomeIcon icon={faSearch} />
                                <span className="text-2xl"> ElasticSearch</span>
                            </div>
                            <div className="items-center text-center">
                                <FontAwesomeIcon icon={faCogs} />
                                <span className="text-2xl"> CI/CD</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export const App: React.FC = () =>
    <div className="body">
        <main>
            <div className="w-1440 mx-auto sm:px-4 pt-12">
                <ScrollArrow />
                <header className="text-center pt-12">
                    <hgroup>
                        <h1>Lewis Tierney</h1>
                        <h2>software engineer</h2>
                    </hgroup>
                </header>
                <AboutSection />
                <SkillsSection />
                <WorkSection />
                <Footer />
            </div>
        </main>
    </div>;

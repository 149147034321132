import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';

export const ScrollArrow: React.FC = () =>{
    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400){
            setShowScroll(false)
        }
    };

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    window.addEventListener('scroll', checkScrollTop)

    return (
        <button title="Go back to the top!" className="hover:bg-gray-300 rounded p-5 scrollTop" onClick={scrollTop} style={{display: showScroll ? 'flex' : 'none'}}>
            <FontAwesomeIcon size="lg" icon={faChevronCircleUp} onClick={scrollTop} />
        </button>
    )
}
